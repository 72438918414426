import View from '../view';

export class UrineOsmolarGapView extends View {
  _title = 'Urine Osmolar Gap';

  _backbone = [
    {
      title: 'urine-sodium',
      units: ['mEq/L'],
    },
    {
      title: 'urine-potassium',
      units: ['mEq/L'],
    },
    {
      title: 'urine-glucose',
      units: ['mg/dl'],
    },
    {
      title: 'urine-urea',
      units: ['mg/dl'],
    },
    {
      title: 'measured-osmolality',
      units: ['mOsm/kg'],
    },
  ];

  _getData() {
    //return object of inputs
    const urineSodiumEl = this._container.querySelector(
      '.eq-input--urine-sodium'
    );

    const urineSodiumUnitEl = this._container.querySelector(
      '.unit-selector--urine-sodium'
    );

    const urinePotassiumEl = this._container.querySelector(
      '.eq-input--urine-potassium'
    );

    const urinePotassiumUnitEl = this._container.querySelector(
      '.unit-selector--urine-potassium'
    );

    const urineGlucoseEl = this._container.querySelector(
      '.eq-input--urine-glucose'
    );

    const urineGlucoseUnitEl = this._container.querySelector(
      '.unit-selector--urine-glucose'
    );

    const urineUreaEl = this._container.querySelector('.eq-input--urine-urea');

    const urineUreaUnitEl = this._container.querySelector(
      '.unit-selector--urine-urea'
    );

    const measuredOsmolalityEl = this._container.querySelector(
      '.eq-input--measured-osmolality'
    );

    const measuredOsmolalityUnitEl = this._container.querySelector(
      '.unit-selector--measured-osmolality'
    );

    const notRightValues = function (values) {
      return values.some((val) => {
        if (!val || val < 0) return true;
      });
    };

    const arr = [
      urineSodiumEl?.value,
      urinePotassiumEl?.value,
      urineGlucoseEl?.value,
      urineUreaEl?.value,
      measuredOsmolalityEl?.value,
    ];

    if (notRightValues(arr)) {
      console.log('error from _getData');
      console.log(arr);
      throw new Error('All values must be entered and must be > 0');
    }

    return {
      urineSodium: {
        value: +urineSodiumEl.value,
        unit: urineSodiumUnitEl.value,
      },
      urinePotassium: {
        value: +urinePotassiumEl.value,
        unit: urinePotassiumUnitEl.value,
      },
      urineGlucose: {
        value: +urineGlucoseEl.value,
        unit: urineGlucoseUnitEl.value,
      },
      urineUrea: {
        value: +urineUreaEl.value,
        unit: urineUreaUnitEl.value,
      },
      measuredOsmolality: {
        value: +measuredOsmolalityEl.value,
        unit: measuredOsmolalityUnitEl.value,
      },
    };
  }
}

export default new UrineOsmolarGapView();
