import { FeView } from './feView';

class FeMagView extends FeView {
  _element = 'magnesium';

  _title = 'FeMg';

  constructor() {
    super(['mmol/L', 'mg/g Cr'], ['mg/dl']);
  }
}

export default new FeMagView();
