import View from '../view';

export class CorrectedCalciumView extends View {
  _title = 'Corrected Calcium';

  _backbone = [
    {
      title: 'serum-calcium',
      units: ['mg/dl'],
    },
    {
      title: 'serum-albumin',
      units: ['g/dl'],
    },
  ];

  _getData() {
    const serumCalciumEl = this._container.querySelector(
      '.eq-input--serum-calcium'
    );

    const serumCalciumUnitEl = this._container.querySelector(
      '.unit-selector--serum-calcium'
    );

    const serumAlbuminEl = this._container.querySelector(
      '.eq-input--serum-albumin'
    );

    const serumAlbuminUnitEl = this._container.querySelector(
      '.unit-selector--serum-albumin'
    );

    const notRightValues = function (values) {
      return values.some((val) => {
        if (!val || val < 0) return true;
      });
    };

    const arr = [serumCalciumEl?.value, serumAlbuminEl?.value];

    if (notRightValues(arr)) {
      console.log('error from _getData');
      console.log(arr);
      throw new Error('All values must be entered and must be > 0');
    }

    return {
      serumCalcium: {
        value: +serumCalciumEl.value,
        unit: serumCalciumUnitEl.value,
      },
      serumAlbumin: {
        value: +serumAlbuminEl.value,
        unit: serumAlbuminUnitEl.value,
      },
    };
  }
}

export default new CorrectedCalciumView();
