import View from '../view';

export class EFreeWaterView extends View {
  _title = 'Electrolyte Free Water';

  _backbone = [
    {
      title: 'urine-sodium',
      units: ['mEq/L'],
    },
    {
      title: 'urine-potassium',
      units: ['mEq/L'],
    },
    {
      title: 'serum-sodium',
      units: ['mmol/L'],
    },
    {
      title: 'urine-volume',
      units: ['ml/hour'],
    },
  ];
  //  // eFWC = V(1 - (Una + Uk / Pna))
  // generateHTML() {
  //   return `   <div class="eq-title-container">
  //   <h1 class="eq-title">${this._title}</h1>
  // </div>
  // <div class="eq-container grid-2-cols">
  //   <div class="eq-component">

  //     <label for="urine-sodium," class="eq-label">Urine Sodium</label>
  //     <div class="input-unit">

  //       <input id="urine-sodium" class="eq-input eq-input--urine-sodium" type="number"/>
  //       <select class="unit-selector unit-selector--urine-sodium">
  //       <option>mEq/L</option>
  //       </select>
  //     </div>
  //   </div>
  //   <div class="eq-component eq-component-right">

  //     <label for="urine-potassium" class="eq-label">Urine Potassium</label>
  //     <div class="input-unit">

  //       <input id="urine-potassium" class="eq-input eq-input--urine-potassium" type="number"/>
  //       <select class="unit-selector unit-selector--urine-potassium">
  //         <option>mEq/L</option>
  //       </select>
  //     </div>
  //   </div>
  //   <div class="eq-component">
  //     <label for="serum-sodium" class="eq-label">Serum Sodium</label>
  //     <div class="input-unit">
  //       <input id="serum-sodium" class="eq-input eq-input--serum-sodium" type="number"/>
  //       <select class="unit-selector unit-selector--serum-sodium">
  //       <option>mmol/L</option>
  //       </select>
  //     </div>
  //   </div>
  //   <div class="eq-component eq-component-right">

  //     <label for="urine-volume" class="eq-label">Urine Volume</label>
  //     <div class="input-unit">

  //       <input id="urine-volume" class="eq-input eq-input--urine-volume" type="number"/>
  //       <select class="unit-selector unit-selector--urine-volume">
  //         <option>ml/hour</option>
  //       </select>
  //     </div>
  //   </div>
  //   <div class="btn-container">
  //   <button class="btn-calculate btn">submit</button>
  // </div>
  // </div>
  // </div>`;
  // }

  _getData() {
    //return object of inputs
    const serumSodiumEl = this._container.querySelector(
      '.eq-input--serum-sodium'
    );

    const serumSodiumUnitEl = this._container.querySelector(
      '.unit-selector--serum-sodium'
    );

    const urinePotassiumEl = this._container.querySelector(
      '.eq-input--urine-potassium'
    );

    const urinePotassiumUnitEl = this._container.querySelector(
      '.unit-selector--urine-potassium'
    );

    const urineSodiumEl = this._container.querySelector(
      '.eq-input--urine-sodium'
    );

    const urineSodiumUnitEl = this._container.querySelector(
      '.unit-selector--urine-sodium'
    );

    const urineVolumeEl = this._container.querySelector(
      '.eq-input--urine-volume'
    );

    const urineVolumeUnitEl = this._container.querySelector(
      '.unit-selector--urine-volume'
    );

    const notRightValues = function (values) {
      return values.some((val) => {
        if (!val || val < 0) return true;
      });
    };

    const arr = [
      serumSodiumEl?.value,
      urinePotassiumEl?.value,
      urineSodiumEl?.value,
      urineVolumeEl?.value,
    ];

    if (notRightValues(arr)) {
      console.log('error from _getData');
      console.log(arr);
      throw new Error('All values must be entered and must be > 0');
    }

    return {
      serumSodium: {
        value: +serumSodiumEl.value,
        unit: serumSodiumUnitEl.value,
      },
      urinePotassium: {
        value: +urinePotassiumEl.value,
        unit: urinePotassiumUnitEl.value,
      },
      urineSodium: {
        value: +urineSodiumEl.value,
        unit: urineSodiumUnitEl.value,
      },
      urineVolume: {
        value: +urineVolumeEl.value,
        unit: urineVolumeUnitEl.value,
      },
    };
  }
}

export default new EFreeWaterView();
