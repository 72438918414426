import { state } from './genModel';

const convertSerumCreatinine = function (creatinine) {
  return creatinine * 0.113;
};

const convertUrineCreatinine = function (creatinine) {
  return creatinine * 11.31;
};

const convertSerumMagnesium = function (mag) {
  return mag * 2.4305;
};

const convertUrineMagPerCreatinine = function (mag, cr) {
  return mag * (cr / 1000);
};

const determineMagnesium = function (values) {
  if (values.serumEl.unit === 'mmol/L') {
    values.serumEl.value = convertSerumMagnesium(values.serumEl.value);
  }
  if (values.urineEl.unit === 'mmol/L') {
    values.urineEl.value = convertSerumMagnesium(values.serumEl.value);
  }

  if (values.urineEl.unit === 'mg/g Cr') {
    values.urineEl.value = convertUrineMagPerCreatinine(
      values.urineEl.value,
      values.urineCr.value
    );
  }
};

const determineCreatinine = function (values) {
  if (values.serumCr.unit === 'µmol/L') {
    values.serumCr.value = convertSerumCreatinine(values.serumCr.value);
  }

  if (values.urineCr.unit === 'mmol/L') {
    values.urineCr.value = convertUrineCreatinine(values.urineCr.value);
  }
};

const calculateFe = function (values, magFactor = 1) {
  state.answer = (
    ((values.urineEl.value * values.serumCr.value) /
      (values.urineCr.value * values.serumEl.value * magFactor)) *
    100
  ).toFixed(2);
  state.formattedAnswer = `
  ${(
    ((values.urineEl.value * values.serumCr.value) /
      (values.urineCr.value * values.serumEl.value * magFactor)) *
    100
  ).toFixed(2)}%`;
};

export const calculateFENa = function (values) {
  determineCreatinine(values);

  calculateFe(values);

  if (state.answer < 1) {
    state.interpretation = 'possible pre-renal azotemia';
  } else {
    state.interpretation = 'possible ATN';
  }
};

export const calculateFEMg = function (values) {
  determineCreatinine(values);

  determineMagnesium(values);

  calculateFe(values, 0.7);
};

export const calculateFeUrea = function (values) {
  determineCreatinine(values);

  calculateFe(values);
};

export const calculateFECa = function (values) {
  determineCreatinine(values);

  calculateFe(values);
};

export const calculateFEPO = function (values) {
  determineCreatinine(values);

  calculateFe(values);
};
