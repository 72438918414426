import View from '../view';

export class UAGView extends View {
  _title = 'Urine Anion Gap';

  _backbone = [
    {
      title: 'urine-sodium',
      units: ['mmol/L'],
    },
    {
      title: 'urine-potassium',
      units: ['mEq/L', 'mmol/L'],
    },
    {
      title: 'urine-chloride',
      units: ['mEq/L', 'mmol/L'],
    },
  ];

  _getData() {
    //return object of inputs
    const urineSodiumEl = this._container.querySelector(
      '.eq-input--urine-sodium'
    );

    const urineSodiumUnitEl = this._container.querySelector(
      '.unit-selector--urine-sodium'
    );

    const urinePotassiumEl = this._container.querySelector(
      '.eq-input--urine-potassium'
    );

    const urinePotassiumUnitEl = this._container.querySelector(
      '.unit-selector--urine-potassium'
    );

    const urineChlorideEl = this._container.querySelector(
      '.eq-input--urine-chloride'
    );

    const urineChlorideUnitEl = this._container.querySelector(
      '.unit-selector--urine-chloride'
    );

    const notRightValues = function (values) {
      return values.some((val) => {
        if (!val || val < 0) return true;
      });
    };

    const arr = [
      urineSodiumEl?.value,
      urinePotassiumEl?.value,
      urineChlorideEl?.value,
    ];

    if (notRightValues(arr)) {
      console.log('error from _getData');
      console.log(arr);
      throw new Error('All values must be entered and must be > 0');
    }

    return {
      urineSodium: {
        value: +urineSodiumEl.value,
        unit: urineSodiumUnitEl.value,
      },
      urinePotassium: {
        value: +urinePotassiumEl.value,
        unit: urinePotassiumUnitEl.value,
      },
      urineChloride: {
        value: +urineChlorideEl.value,
        unit: urineChlorideUnitEl.value,
      },
    };
  }
}

export default new UAGView();
