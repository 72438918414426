class AnswerView {
  _container = document.querySelector('.answer-container');
  _answer;
  _calculation;
  _message = 'All values must be entered and must be > 0 😢';

  render(data) {
    this._formattedAnswer = data.formattedAnswer;
    this._interpretation = data.interpretation;
    this._calculation = data.calculation;
    const html = this._generateHTML();
    this.clear();
    this._container.insertAdjacentHTML('afterbegin', html);
  }

  renderError(message = this._message) {
    const html = `<p class=answer>${message}</p>`;
    this.clear();
    this._container.insertAdjacentHTML('afterbegin', html);
  }

  clear() {
    this._container.innerHTML = '';
  }

  _generateHTML() {
    return `
    <p class="answer">${this._calculation}: ${this._formattedAnswer}<span class="tooltip"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="tooltip-icon">
    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
  </svg>
  </span></p>`;
  }

  addHandlerRender(handler) {
    this._container.addEventListener('click', function (e) {
      if (!e.target.classList.contains('tooltip-icon')) return;

      handler();

   
    });
  }
}

export default new AnswerView();
