import View from '../view';

export class OsmolarGapView extends View {
  _title = 'Osmolar Gap';

  _backbone = [
    {
      title: 'serum-sodium',
      units: ['mmol/L'],
    },
    {
      title: 'serum-glucose',
      units: ['mg/dl'],
    },
    {
      title: 'serum-BUN',
      units: ['mg/dl'],
    },
    {
      title: 'measured-osmolality',
      units: ['mOsm/kg'],
    },
    {
      title: 'serum-etoh',
      units: ['mg/dl'],
    },
  ];

  _getData() {
    //return object of inputs
    const serumSodiumEl = this._container.querySelector(
      '.eq-input--serum-sodium'
    );

    const serumSodiumUnitEl = this._container.querySelector(
      '.unit-selector--serum-sodium'
    );

    const serumGlucoseEl = this._container.querySelector(
      '.eq-input--serum-glucose'
    );

    const serumGlucoseUnitEl = this._container.querySelector(
      '.unit-selector--serum-glucose'
    );

    const serumBUNEl = this._container.querySelector('.eq-input--serum-BUN');

    const serumBUNUnitEl = this._container.querySelector(
      '.unit-selector--serum-BUN'
    );

    const measuredOsmolalityEl = this._container.querySelector(
      '.eq-input--measured-osmolality'
    );

    const measuredOsmolalityUnitEl = this._container.querySelector(
      '.unit-selector--measured-osmolality'
    );

    const serumETOHEl = this._container.querySelector('.eq-input--serum-etoh');

    const serumETOHUnitEl = this._container.querySelector(
      '.unit-selector--serum-etoh'
    );

    const notRightValues = function (values) {
      return values.some((val) => {
        if (!val || val < 0) return true;
      });
    };

    const arr = [
      serumSodiumEl?.value,
      serumGlucoseEl?.value,
      serumBUNEl?.value,
      measuredOsmolalityEl?.value,
    ];

    if (notRightValues(arr)) {
      console.log('error from _getData');
      console.log(arr);
      throw new Error('All values must be entered and must be > 0');
    }

    return {
      serumSodium: {
        value: +serumSodiumEl.value,
        unit: serumSodiumUnitEl.value,
      },
      serumGlucose: {
        value: +serumGlucoseEl.value,
        unit: serumGlucoseUnitEl.value,
      },
      serumBUN: {
        value: +serumBUNEl.value,
        unit: serumBUNUnitEl.value,
      },
      measuredOsmolality: {
        value: +measuredOsmolalityEl.value,
        unit: measuredOsmolalityUnitEl.value,
      },
      serumETOH: {
        value: +serumETOHEl.value,
        unit: serumETOHUnitEl.value,
      },
    };
  }
}

export default new OsmolarGapView();
