import { FeView } from './feView.js';

class FeUreaView extends FeView {
  _title = 'FeUrea';
  _element = 'urea';

  constructor() {
    super(['mg/dl', 'mEq/L'], ['mg/dl', 'mEq/L']);
  }
}

export default new FeUreaView();
