class InterpretationView {
  _interpretationContainer = document.querySelector(
    '.interpretation-container'
  );

  render(data) {
    this._interpretation = data.interpretation;

    const html = `<p class="interpretation-text">${this._interpretation}</p>`;

    this._clear();
    this._interpretationContainer.insertAdjacentHTML('afterbegin', html);
  }

  _clear() {
    this._interpretationContainer.innerHTML = '';
  }
}

export default new InterpretationView();
