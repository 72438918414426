import View from '../view';

export class CorrectedSodiumView extends View {
  _title = 'Corrected Sodium';

  _backbone = [
    {
      title: 'serum-glucose',
      units: ['mg/dl'],
    },
    {
      title: 'serum-sodium',
      units: ['mEq/L'],
    },
  ];

  _getData() {
    const serumGlucoseEl = this._container.querySelector(
      '.eq-input--serum-glucose'
    );

    const serumGlucoseUnitEl = this._container.querySelector(
      '.unit-selector--serum-glucose'
    );

    const serumSodiumEl = this._container.querySelector(
      '.eq-input--serum-sodium'
    );

    const serumSodiumUnitEl = this._container.querySelector(
      '.unit-selector--serum-sodium'
    );

    const notRightValues = function (values) {
      return values.some((val) => {
        if (!val || val < 0) return true;
      });
    };

    const arr = [serumGlucoseEl?.value, serumSodiumEl?.value];

    if (notRightValues(arr)) {
      console.log('error from _getData');
      console.log(arr);
      throw new Error('All values must be entered and must be > 0');
    }

    return {
      serumGlucose: {
        value: +serumGlucoseEl.value,
        unit: serumGlucoseUnitEl.value,
      },
      serumSodium: {
        value: +serumSodiumEl.value,
        unit: serumSodiumUnitEl.value,
      },
    };
  }
}

export default new CorrectedSodiumView();
