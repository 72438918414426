export const state = {
  answer: '',
  formattedAnswer: '',
  calculation: '',
  interpretation: '',
};

export const addCalculationType = function (calctype) {
  state.calculation = calctype;
};

export const calculateOsmolarGap = function (values) {
  const sodium = 2 * values.serumSodium.value;
  const glucose = values.serumGlucose.value / 18;
  const BUN = values.serumBUN.value / 2.8;
  const ETOH = values.serumETOH.value / 4.6;

  state.answer = (
    values.measuredOsmolality.value -
    (sodium + glucose + BUN + ETOH)
  ).toFixed(1);
  state.formattedAnswer = (
    values.measuredOsmolality.value -
    (sodium + glucose + BUN + ETOH)
  ).toFixed(1);
};

export const calculateEFreeWaterClearance = function (values) {
  // eFWC = V(1 - (Una + Uk / Pna))
  //V is in ml/hour and answer is ml/hr

  state.answer =
    values.urineVolume.value *
    (
      1 -
      (values.urineSodium.value + values.urinePotassium.value) /
        values.serumSodium.value
    ).toFixed(1);
  state.formattedAnswer = `${(
    values.urineVolume.value *
    (1 -
      (values.urineSodium.value + values.urinePotassium.value) /
        values.serumSodium.value)
  ).toFixed(1)} ml/hour`;
};

export const calculateFreeWaterDeficit = function (values) {
  let percentageTBW = 0.6;
  if (values.maleGender.value === false) percentageTBW = 0.5;

  state.answer =
    values.weight.value *
    percentageTBW *
    (values.serumSodium.value / values.goalSodium.value - 1).toFixed(1);
  state.formattedAnswer = `${(
    values.weight.value *
    percentageTBW *
    (values.serumSodium.value / values.goalSodium.value - 1)
  ).toFixed(1)} L`;
  //Free water deficit: = TBW(kg) x (Measured Na / Ideal Na – 1)
  //male is 60% water and female is 50% water.
};

export const calculateUrineAnionGap = function (values) {
  //na + k - cl

  const result =
    values.urineSodium.value +
    values.urinePotassium.value -
    values.urineChloride.value;

  state.answer = result;
  state.formattedAnswer = `${result > 0 ? '+' : ''}${result}`;
};

export const calculateCrCl = function (values) {
  //CrCl (ml/min) = UCr x UVol(ml) / SCr x 1440
  const result = (
    (values.urineCreatinine.value * values.urineVolume.value) /
    (values.serumCreatinine.value * 1440)
  ).toFixed(2);

  state.answer = result;
  state.formattedAnswer = `${result} ml/min`;
};

export const calculateTTKG = function (values) {
  //ttkg = urine K(mmol/L) x serumOsm(mOsm/kg) / serumK(mmol/L) X UOsm(mOsm/kg)
  //high ttkg in setting of high K means not properly excreting
  //low ttkg in setting of hypokalemia suggests potassium wasting.

  const result = (
    (values.urinePotassium.value * values.serumOsm.value) /
    (values.serumPotassium.value * values.urineOsm.value)
  ).toFixed(2);

  state.answer = result;
  state.formattedAnswer = result;
};

export const calculateWinters = function (values) {
  //expected PCO2mmHg = (1.5 x HCO3mEq/L) + 8 +/- 2

  const result = (1.5 * values.serumBicarb.value + 8).toFixed(1);

  state.answer = result;
  state.formattedAnswer = `${result} +/- 2 mmHg`;
};

export const calculateSerumAnionGap = function (values) {
  const result =
    values.serumSodium.value -
    (values.serumChloride.value + values.serumBicarb.value) +
    2.5 * (4 - values.serumAlbumin.value);

  state.answer = result;
  state.formattedAnswer = result;
};

export const caluclateCorrectedSodium = function (values) {
  const firstResult = 0.016 * (values.serumGlucose.value - 100);
  const result = values.serumSodium.value + firstResult;

  state.answer = result;
  state.formattedAnswer = `${result} mEq/L`;
};

export const calculateUrineOsmolarGap = function (values) {
  //uag = measured u/osm - calc u/osm
  //calc = 2 X (Na + K) + urea mg/dl / 2.8 + glucose mg/dl / 2.8

  const sodiumPotassium =
    2 * (values.urineSodium.value + values.urinePotassium.value);
  const ureaNitrogen = values.urineUrea.value / 2.8;
  const glucose = values.urineGlucose.value / 18;
  const result = (
    values.measuredOsmolality.value -
    (sodiumPotassium + ureaNitrogen + glucose)
  ).toFixed(2);

  state.answer = result;
  state.formattedAnswer = result;
};

export const calculateCorrectedCalcium = function (values) {
  let result;

  if (values.serumAlbumin.value >= 4) {
    result = values.serumCalcium.value.toFixed(1);
    return (state.formattedAnswer = `${result} mg/dl`);
  }

  result = (
    (4 - values.serumAlbumin.value) * 0.8 +
    values.serumCalcium.value
  ).toFixed(1);

  state.answer = result;
  state.formattedAnswer = `${result} mg/dl`;
};

export const calculateTPV = function (values) {
  const TBV = 70 * values.patientWeight.value; //in kg and gives ml
  const result = (
    (TBV * (1 - values.patientHematocrit.value / 100)) /
    1000
  ).toFixed(1);

  state.answer = result;

  state.formattedAnswer = `${result} L`;
};
