import View from '../view';

export class FreeWaterDefView extends View {
  _male = true;

  _title = 'Free Water Deficit';

  _additionalHTML = `<div class="eq-component">

    <label class="eq-label label--gender">Gender</label>
    <div class="gender-container">
      <button class="btn btn--gender btn--male">Male</button>
      <button class="btn btn--gender btn--female">Female</button>
    </div>
  </div>`;

  _backbone = [
    {
      title: 'serum-sodium',
      units: ['mmol/L'],
    },
    {
      title: 'goal-sodium',
      units: ['mEq/L'],
    },
    {
      title: 'patient-weight',
      units: ['kg'],
    },
  ];

  _changeGender(e) {
    const genderBtn = e.target.closest('.btn--gender');

    if (!genderBtn) return;

    const genderBtns = document.querySelectorAll('.btn--gender');

    genderBtns.forEach((btn) => {
      btn.classList.remove('gender-clicked');
    });

    genderBtn.classList.add('gender-clicked');

    if (genderBtn.classList.contains('btn--female')) {
      this._male = false;
    } else {
      this._male = true;
    }
  }

  addHandlerGender() {
    const container = document.querySelector('.gender-container');

    container.addEventListener('click', this._changeGender.bind(this));
  }

  _getData() {
    //return object of inputs
    const serumSodiumEl = this._container.querySelector(
      '.eq-input--serum-sodium'
    );

    const serumSodiumUnitEl = this._container.querySelector(
      '.unit-selector--serum-sodium'
    );

    const goalSodiumEl = this._container.querySelector(
      '.eq-input--goal-sodium'
    );

    const goalSodiumUnitEl = this._container.querySelector(
      '.unit-selector--goal-sodium'
    );

    const weightEl = this._container.querySelector('.eq-input--patient-weight');

    const weightUnitEl = this._container.querySelector(
      '.unit-selector--patient-weight'
    );

    const notRightValues = function (values) {
      return values.some((val) => {
        if (!val || val < 0) return true;
      });
    };

    console.log(serumSodiumEl?.value, goalSodiumEl?.value, weightEl?.value);

    const valuesArr = [
      serumSodiumEl?.value,
      goalSodiumEl?.value,
      weightEl?.value,
    ];

    if (notRightValues(valuesArr)) {
      console.log('error from _getData');

      throw new Error('All values must be entered and must be > 0');
    }

    return {
      serumSodium: {
        value: +serumSodiumEl.value,
        unit: serumSodiumUnitEl.value,
      },
      goalSodium: {
        value: +goalSodiumEl.value,
        unit: goalSodiumUnitEl.value,
      },
      weight: {
        value: +weightEl.value,
        unit: weightUnitEl.value,
      },
      maleGender: {
        value: this._male,
      },
    };
  }
}

export default new FreeWaterDefView();
