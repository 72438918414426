import { FeView } from './feView';

class FEPOView extends FeView {
  _element = 'phosphorous';

  _title = 'FePO4';

  constructor() {
    super(['mg/dl'], ['mg/dl']);
  }
}

export default new FEPOView();
