import View from '../view';

export class CrClView extends View {
  _title = '24h CrCl';

  _backbone = [
    {
      title: 'urine-creatinine',
      units: ['mg/dl'],
    },
    {
      title: 'serum-creatinine',
      units: ['mg/dl'],
    },
    {
      title: 'urine-volume',
      units: ['ml/24h'],
    },
  ];

  _getData() {
    //return object of inputs
    const urineCreatinineEl = this._container.querySelector(
      '.eq-input--urine-creatinine'
    );

    const urineCreatinineUnitEl = this._container.querySelector(
      '.unit-selector--urine-creatinine'
    );

    const serumCreatinineEl = this._container.querySelector(
      '.eq-input--serum-creatinine'
    );

    const serumCreatinineUnitEl = this._container.querySelector(
      '.unit-selector--serum-creatinine'
    );

    const urineVolumeEl = this._container.querySelector(
      '.eq-input--urine-volume'
    );

    const urineVolumeUnitEl = this._container.querySelector(
      '.unit-selector--urine-volume'
    );

    const notRightValues = function (values) {
      return values.some((val) => {
        if (!val || val < 0) return true;
      });
    };

    const arr = [
      urineCreatinineEl?.value,
      serumCreatinineEl?.value,
      urineVolumeEl?.value,
    ];

    if (notRightValues(arr)) {
      console.log('error from _getData');
      console.log(arr);
      throw new Error('All values must be entered and must be > 0');
    }

    return {
      urineCreatinine: {
        value: +urineCreatinineEl.value,
        unit: urineCreatinineUnitEl.value,
      },
      serumCreatinine: {
        value: +serumCreatinineEl.value,
        unit: serumCreatinineUnitEl.value,
      },
      urineVolume: {
        value: +urineVolumeEl.value,
        unit: urineVolumeUnitEl.value,
      },
    };
  }
}

export default new CrClView();
