import View from '../view';

export class TotalPlasmaVolumeView extends View {
  _title = 'Total Plasma volume';

  _backbone = [
    {
      title: 'patient-weight',
      units: ['kg'],
    },
    {
      title: 'patient-hematocrit',
      units: ['%'],
    },
  ];

  _getData() {
    const patientWeightEl = this._container.querySelector(
      '.eq-input--patient-weight'
    );

    const patientWeightUnitEl = this._container.querySelector(
      '.unit-selector--patient-weight'
    );

    const patientHematocritEl = this._container.querySelector(
      '.eq-input--patient-hematocrit'
    );

    const patientHematocritUnitEl = this._container.querySelector(
      '.unit-selector--patient-hematocrit'
    );

    const notRightValues = function (values) {
      return values.some((val) => {
        if (!val || val < 0) return true;
      });
    };

    const arr = [patientWeightEl?.value, patientHematocritEl?.value];

    if (notRightValues(arr)) {
      console.log('error from _getData');
      console.log(arr);
      throw new Error('All values must be entered and must be > 0');
    }

    return {
      patientWeight: {
        value: +patientWeightEl.value,
        unit: patientWeightUnitEl.value,
      },
      patientHematocrit: {
        value: +patientHematocritEl.value,
        unit: patientHematocritUnitEl.value,
      },
    };
  }
}

export default new TotalPlasmaVolumeView();
