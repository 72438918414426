import View from '../view';

export class TTKGView extends View {
  _title = 'TTKG';

  _backbone = [
    {
      title: 'urine-potassium',
      units: ['mmol/L'],
    },
    {
      title: 'serum-osmolality',
      units: ['mOsm/kg'],
    },
    {
      title: 'serum-potassium',
      units: ['mmol/L'],
    },
    {
      title: 'urine-osmolality',
      units: ['mOsm/kg'],
    },
  ];

  //ttkg = urine K(mmol/L) x serumOsm(mOsm/kg) / serumK(mmol/L) X UOsm(mOsm/kg)
  //high ttkg in setting of high K means not properly excreting
  //low ttkg in setting of hypokalemia suggests potassium wasting.

  _getData() {
    //return object of inputs
    const urinePotassiumEl = this._container.querySelector(
      '.eq-input--urine-potassium'
    );

    const urinePotassiumUnitEl = this._container.querySelector(
      '.unit-selector--urine-potassium'
    );

    const serumPotassiumEl = this._container.querySelector(
      '.eq-input--serum-potassium'
    );

    const serumPotassiumUnitEl = this._container.querySelector(
      '.unit-selector--serum-potassium'
    );

    const serumOsmEl = this._container.querySelector(
      '.eq-input--serum-osmolality'
    );

    const serumOsmUnitEl = this._container.querySelector(
      '.unit-selector--serum-osmolality'
    );

    const urineOsmEl = this._container.querySelector(
      '.eq-input--urine-osmolality'
    );

    const urineOsmUnitEl = this._container.querySelector(
      '.unit-selector--urine-osmolality'
    );

    const notRightValues = function (values) {
      return values.some((val) => {
        if (!val || val < 0) return true;
      });
    };

    const arr = [
      urinePotassiumEl?.value,
      serumPotassiumEl?.value,
      serumOsmEl?.value,
      urineOsmEl?.value,
    ];

    if (notRightValues(arr)) {
      console.log('error from _getData');
      console.log(arr);
      throw new Error('All values must be entered and must be > 0');
    }

    return {
      urinePotassium: {
        value: +urinePotassiumEl.value,
        unit: urinePotassiumUnitEl.value,
      },
      serumPotassium: {
        value: +serumPotassiumEl.value,
        unit: serumPotassiumUnitEl.value,
      },
      serumOsm: {
        value: +serumOsmEl.value,
        unit: serumOsmUnitEl.value,
      },
      urineOsm: {
        value: +urineOsmEl.value,
        unit: urineOsmUnitEl.value,
      },
    };
  }
}

export default new TTKGView();
