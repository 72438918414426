export default class View {
  _container = document.querySelector('.changeable-container');

  _clear() {
    this._container.innerHTML = '';
  }

  render() {
    const html = this.generateFullHTML();

    this._clear();

    this._container.insertAdjacentHTML('afterbegin', html);
  }

  generateFullHTML() {
    return `<div class="eq-title-container">
                    <h1 class="eq-title">${this._title}</h1>
            </div>
  <div class="eq-container grid-2-cols">
  ${this._backbone
    .map((obj, i, arr) => {
      let label;

      const numOfItems = arr.length;

      if (obj.title.includes('serum-element')) {
        label = `Serum ${
          this._element[0].toUpperCase() + this._element.slice(1)
        }`;
      }

      if (obj.title.includes('urine-element')) {
        label = `Urine ${
          this._element[0].toUpperCase() + this._element.slice(1)
        }`;
      }
      return `<div class="eq-component ${
        i === numOfItems - 1 && numOfItems % 2 !== 0 && !this._additionalHTML
          ? 'eq-component--center'
          : ''
      }">
                  <label for="${obj.title}" class="eq-label">${
        label
          ? label
          : obj.title
              .split('-')
              .map((word) => word[0].toUpperCase() + word.slice(1))
              .join(' ')
      }</label>
                      <div class="input-unit">
                          <input id="${obj.title}" class="eq-input eq-input--${
        obj.title
      }" type="number"/>
                          <select class="unit-selector unit-selector--${
                            obj.title
                          }">
                          ${obj.units
                            .map((unit) => {
                              return `<option>${unit}</option>`;
                            })
                            .join('')}                         
                          </select>
                      </div>
                </div>`;
    })
    .join('')}
    ${this._additionalHTML ? this._additionalHTML : ''}
        <div class="btn-container">
            <button class="btn-calculate btn">submit</button>
        </div>
  </div>`;
  }

  addHandlerRender(handler) {
    const btn = this._container.querySelector('.btn-calculate');

    const that = this;
    btn.addEventListener('click', function (e) {
      e.preventDefault();

      try {
        const data = that._getData();

        handler(data);
      } catch (err) {
        console.log('error from addhandlerrender');
        handler(err);
      }
    });
  }
}
