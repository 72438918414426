import View from '../view';

export class FeView extends View {
  constructor(urineUnits, serumUnits) {
    super();
    this._urineUnits = urineUnits;
    this._serumUnits = serumUnits;

    this._backbone = [
      {
        title: 'urine-element',
        units: this._urineUnits,
      },
      {
        title: 'serum-element',
        units: this._serumUnits,
      },
      {
        title: 'serum-creatinine',
        units: ['mg/dl', 'µmol/L'],
      },
      {
        title: 'urine-creatinine',
        units: ['mg/dl', 'mmol/L'],
      },
    ];
  }

  _getData() {
    //return object of inputs
    const urineElementEl = this._container.querySelector(
      '.eq-input--urine-element'
    );

    const urineElementUnitEl = this._container.querySelector(
      '.unit-selector--urine-element'
    );
    const urineCreatinineEl = this._container.querySelector(
      '.eq-input--urine-creatinine'
    );

    const urineCreatinineUnitEl = this._container.querySelector(
      '.unit-selector--urine-creatinine'
    );
    const serumElementEl = this._container.querySelector(
      '.eq-input--serum-element'
    );

    const serumElementUnitEl = this._container.querySelector(
      '.unit-selector--serum-element'
    );

    const serumCreatinineEl = this._container.querySelector(
      '.eq-input--serum-creatinine'
    );

    const serumCreatinineUnitEl = this._container.querySelector(
      '.unit-selector--serum-creatinine'
    );

    const notRightValues = function (values) {
      return values.some((val) => {
        if (!val || val < 0) return true;
      });
    };

    const arr = [
      urineElementEl?.value,
      urineCreatinineEl?.value,
      serumCreatinineEl?.value,
      serumElementEl?.value,
    ];

    if (notRightValues(arr)) {
      console.log('error from _getData');
      console.log(arr);
      throw new Error('All values must be entered and must be > 0');
    }

    return {
      urineEl: {
        value: +urineElementEl.value,
        unit: urineElementUnitEl.value,
      },
      urineCr: {
        value: +urineCreatinineEl.value,
        unit: urineCreatinineUnitEl.value,
      },
      serumEl: {
        value: +serumElementEl.value,
        unit: serumElementUnitEl.value,
      },
      serumCr: {
        value: +serumCreatinineEl.value,
        unit: serumCreatinineUnitEl.value,
      },
    };
  }

  // generateHTML() {
  //   return `   <div class="eq-title-container">
  //   <h1 class="eq-title">${this._title}</h1>
  // </div>
  // <div class="eq-container grid-2-cols">
  //   <div class="eq-component">

  //     <label for="serum-element" class="eq-label">Serum ${
  //       this._element[0].toUpperCase() + this._element.slice(1)
  //     }</label>
  //     <div class="input-unit">

  //       <input id="serum-element" class="eq-input eq-input--serum-element" type="number"/>
  //       <select class="unit-selector unit-selector--serum-element">
  //       ${this._unitsSerumHTML}
  //       </select>
  //     </div>
  //   </div>
  //   <div class="eq-component eq-component-right">

  //     <label for="serum-creatinine" class="eq-label">Serum Creatinine</label>
  //     <div class="input-unit">

  //       <input id="serum-creatinine" class="eq-input eq-input--serum-creatinine" type="number"/>
  //       <select class="unit-selector unit-selector--serum-creatinine">
  //         <option>mg/dl</option>
  //         <option>µmol/L</option>
  //       </select>
  //     </div>
  //   </div>
  //   <div class="eq-component">

  //     <label for="urine-element" class="eq-label">Urine ${
  //       this._element[0].toUpperCase() + this._element.slice(1)
  //     }</label>
  //     <div class="input-unit">

  //       <input id="urine-element" class="eq-input eq-input--urine-element" type="number"/>
  //       <select class="unit-selector unit-selector--urine-element">
  //         ${this._unitsUrineHTML}
  //       </select>
  //     </div>
  //   </div>
  //   <div class="eq-component eq-component-right">

  //     <label for="urine-creatinine" class="eq-label">Urine Creatinine</label>
  //     <div class="input-unit">

  //       <input id="urine-creatinine" class="eq-input eq-input--urine-creatinine" type="number"/>
  //       <select class="unit-selector unit-selector--urine-creatinine">
  //         <option>mg/dl</option>
  //         <option>mmol/L</option>
  //       </select>
  //     </div>
  //   </div>
  //   <div class="btn-container">
  //     <button class="btn-calculate btn">submit</button>
  //   </div>
  // </div>`;
  // }
}
