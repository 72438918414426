class NavView {
  _navBar = document.querySelector('.navbar');
  _sideIcon = document.querySelector('.side-icon');
  _body = document.querySelector('body');
  _answerContainer = document.querySelector('.answer-container');
  _interpretationContainer = document.querySelector(
    '.interpretation-container'
  );

  constructor() {
    this._handleSideMenu();
  }

  addHandlerRender(handler) {
    this._navBar.addEventListener('click', function (e) {
      e.preventDefault();

      const btn = e.target.closest('.eq-link');

      if (!btn) return;

      const equation = btn.dataset.eq;

      handler(equation);
    });
  }

  toggleSideMenu() {
    document.querySelector('.changeable-container').innerHTML = '';
    this._sideIcon.classList.toggle('hidden-icon');
    this._navBar.classList.toggle('hidden-mobile');
    this._body.classList.toggle('mobile-selected');
    this._answerContainer.innerHTML = '';
    this._interpretationContainer.innerHTML = '';
  }

  _handleSideMenu() {
    this._sideIcon.addEventListener('click', this.toggleSideMenu.bind(this));
  }
}

export default new NavView();
