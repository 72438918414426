import View from '../view';

export class WintersView extends View {
  _title = "Winter's formula";

  _backbone = [
    {
      title: 'serum-bicarb',
      units: ['mEq/L'],
    },
  ];

  _getData() {
    //return object of inputs
    const serumBicarbEl = this._container.querySelector(
      '.eq-input--serum-bicarb'
    );

    const serumBicarbUnitEl = this._container.querySelector(
      '.unit-selector--serum-bicarb'
    );

    const notRightValues = function (values) {
      return values.some((val) => {
        if (!val || val < 0) return true;
      });
    };

    const arr = [serumBicarbEl?.value];

    if (notRightValues(arr)) {
      console.log('error from _getData');
      console.log(arr);
      throw new Error('All values must be entered and must be > 0');
    }

    return {
      serumBicarb: {
        value: +serumBicarbEl.value,
        unit: serumBicarbUnitEl.value,
      },
    };
  }
}

export default new WintersView();
