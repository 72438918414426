import { FeView } from './feView';

class FeCaView extends FeView {
  _element = 'calcium';

  _title = 'FeCa';

  constructor() {
    super(['mg/dl'], ['mg/dl']);
  }
}

export default new FeCaView();
