import View from '../view';

export class SerumAnionGapView extends View {
  _title = 'Serum Anion Gap';

  _backbone = [
    {
      title: 'serum-sodium',
      units: ['mEq/L'],
    },
    {
      title: 'serum-chloride',
      units: ['mEq/L'],
    },
    {
      title: 'serum-bicarb',
      units: ['mEq/L'],
    },
    {
      title: 'serum-albumin',
      units: ['g/dl'],
    },
  ];

  _getData() {
    //return object of inputs
    const serumSodiumEl = this._container.querySelector(
      '.eq-input--serum-sodium'
    );

    const serumSodiumUnitEl = this._container.querySelector(
      '.unit-selector--serum-sodium'
    );

    const serumChlorideEl = this._container.querySelector(
      '.eq-input--serum-chloride'
    );

    const serumChlorideUnitEl = this._container.querySelector(
      '.unit-selector--serum-chloride'
    );

    const serumBicarbEl = this._container.querySelector(
      '.eq-input--serum-bicarb'
    );

    const serumBicarbUnitEl = this._container.querySelector(
      '.unit-selector--serum-bicarb'
    );

    const serumAlbuminEl = this._container.querySelector(
      '.eq-input--serum-albumin'
    );

    const serumAlbuminUnitEl = this._container.querySelector(
      '.unit-selector--serum-albumin'
    );

    const notRightValues = function (values) {
      return values.some((val) => {
        if (!val || val < 0) return true;
      });
    };

    const arr = [
      serumSodiumEl?.value,
      serumChlorideEl?.value,
      serumBicarbEl?.value,
      serumAlbuminEl?.value,
    ];

    if (notRightValues(arr)) {
      console.log('error from _getData');
      console.log(arr);
      throw new Error('All values must be entered and must be > 0');
    }

    return {
      serumSodium: {
        value: +serumSodiumEl.value,
        unit: serumSodiumUnitEl.value,
      },
      serumChloride: {
        value: +serumChlorideEl.value,
        unit: serumChlorideUnitEl.value,
      },
      serumBicarb: {
        value: +serumBicarbEl.value,
        unit: serumBicarbUnitEl.value,
      },
      serumAlbumin: {
        value: +serumAlbuminEl.value,
        unit: serumAlbuminUnitEl.value,
      },
    };
  }
}

export default new SerumAnionGapView();
