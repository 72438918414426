import { FeView } from './feView';

class FeNaView extends FeView {
  _title = 'FeNa';
  _element = 'sodium';

  constructor() {
    super(['mEq/L'], ['mEq/L']);
  }
}

export default new FeNaView();
