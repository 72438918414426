import navView from './Views/navView';
import interpretationView from './Views/interpretationView';
import answerView from './Views/answerView';
import feMagView from './Views/fractionalViews/feMagView';
import fenaView from './Views/fractionalViews/feNaView';
import feUreaView from './Views/fractionalViews/feUreaView';
import feCaView from './Views/fractionalViews/feCaView';
import fePOView from './Views/fractionalViews/fePOView';
import osmolarGapView from './Views/acidBase/osmolarGapView';
import eFreeWaterView from './Views/sodium/EFreeWaterView';
import freeWaterDefView from './Views/sodium/freeWaterDefView';
import UAGView from './Views/acidBase/UAGView';
import CrClView from './Views/clearance/CrClView';
import TTKGView from './Views/potassium/TTKGView';
import wintersView from './Views/acidBase/wintersView';
import serumAnionGapView from './Views/acidBase/serumAnionGapView';
import correctedSodiumView from './Views/sodium/correctedSodiumView';
import urineOsmolarGapView from './Views/acidBase/urineOsmolarGapView';
import correctedCalciumView from './Views/calcium/correctedCalciumView';
import totalPlasmaVolumeView from './Views/clearance/totalPlasmaVolumeView';
import * as fractionalModel from './models/fractionalModel';
import * as genModel from './models/genModel';
import metaImage from '../images/nephcalc-high-resolution-logo-twitter.png';

const controlNav = function (title) {
  answerView.clear();

  if (!title) return;

  genModel.addCalculationType(title);

  navView.toggleSideMenu();

  switch (title) {
    case 'FENa':
      fenaView.render();
      fenaView.addHandlerRender(controlFENa);
      break;
    case 'FEMg':
      feMagView.render();
      feMagView.addHandlerRender(controlFEMg);
      break;
    case 'FeUrea':
      feUreaView.render();
      feUreaView.addHandlerRender(controlFeUrea);
      break;
    case 'OsmolarGap':
      osmolarGapView.render();
      osmolarGapView.addHandlerRender(controlOsmolarGap);
      genModel.addCalculationType('Osmolar Gap');
      break;
    case 'EFreeWater':
      eFreeWaterView.render();
      eFreeWaterView.addHandlerRender(controlEFreeWaterClearance);
      genModel.addCalculationType('Free Water Clearance');
      break;
    case 'FreeWaterDef':
      freeWaterDefView.render();
      freeWaterDefView.addHandlerRender(controlFreeWaterDef);
      freeWaterDefView.addHandlerGender();
      genModel.addCalculationType('Free Water Deficit');
      break;

    case 'UAG':
      UAGView.render();
      UAGView.addHandlerRender(controlUAG);
      genModel.addCalculationType('Urine Anion Gap');
      break;

    case 'CrCl':
      CrClView.render();
      CrClView.addHandlerRender(controlCrCl);
      genModel.addCalculationType('CrCl');
      break;

    case 'TTKG':
      TTKGView.render();
      TTKGView.addHandlerRender(controlTTKG);
      genModel.addCalculationType('TTKG');
      break;

    case 'Winters':
      wintersView.render();
      wintersView.addHandlerRender(controlWinters);
      genModel.addCalculationType('Expected PCO2');
      break;

    case 'SAG':
      serumAnionGapView.render();
      serumAnionGapView.addHandlerRender(controlSerumAnionGap);
      genModel.addCalculationType('Anion Gap');
      break;

    case 'FECa':
      feCaView.render();
      feCaView.addHandlerRender(controlFECa);
      break;

    case 'FEPO4':
      fePOView.render();
      fePOView.addHandlerRender(controlFEPO);
      break;
    case 'CorrectedSodium':
      correctedSodiumView.render();
      correctedSodiumView.addHandlerRender(controlCorrectedSodium);
      genModel.addCalculationType('Corrected');

      break;
    case 'UOG':
      urineOsmolarGapView.render();
      urineOsmolarGapView.addHandlerRender(controlUrineOsmolarGap);
      genModel.addCalculationType('Gap');
      break;
    case 'CorrectedCalcium':
      correctedCalciumView.render();
      correctedCalciumView.addHandlerRender(controlCorrectedCalcium);
      genModel.addCalculationType('Corrected');
      break;
    case 'TPV':
      totalPlasmaVolumeView.render();
      totalPlasmaVolumeView.addHandlerRender(controlTPV);
      genModel.addCalculationType('TPV');
      break;

    default:
      break;
  }
};

const controlInterpretation = function () {
  interpretationView.render(genModel.state);
};

const genControl = function (values, calculation) {
  try {
    if (values instanceof Error) {
      throw Error(values);
    }
    calculation(values);
    answerView.render(genModel.state);
  } catch (err) {
    console.log(err);
    answerView.renderError();
  }
};

const controlFENa = function (values) {
  genControl(values, fractionalModel.calculateFENa);
};

const controlFEMg = function (values) {
  genControl(values, fractionalModel.calculateFEMg);
};

const controlFeUrea = function (values) {
  genControl(values, fractionalModel.calculateFeUrea);
};

const controlOsmolarGap = function (values) {
  genControl(values, genModel.calculateOsmolarGap);
};

const controlEFreeWaterClearance = function (values) {
  genControl(values, genModel.calculateEFreeWaterClearance);
};

const controlFreeWaterDef = function (values) {
  genControl(values, genModel.calculateFreeWaterDeficit);
};

const controlUAG = function (values) {
  genControl(values, genModel.calculateUrineAnionGap);
};

const controlCrCl = function (values) {
  genControl(values, genModel.calculateCrCl);
};

const controlTTKG = function (values) {
  genControl(values, genModel.calculateTTKG);
};

const controlWinters = function (values) {
  genControl(values, genModel.calculateWinters);
};

const controlSerumAnionGap = function (values) {
  genControl(values, genModel.calculateSerumAnionGap);
};

const controlFECa = function (values) {
  genControl(values, fractionalModel.calculateFECa);
};

const controlFEPO = function (values) {
  genControl(values, fractionalModel.calculateFEPO);
};

const controlCorrectedSodium = function (values) {
  genControl(values, genModel.caluclateCorrectedSodium);
};

const controlCorrectedCalcium = function (values) {
  genControl(values, genModel.calculateCorrectedCalcium);
};

const controlUrineOsmolarGap = function (values) {
  genControl(values, genModel.calculateUrineOsmolarGap);
};

const controlTPV = function (values) {
  genControl(values, genModel.calculateTPV);
};

const init = function () {
  navView.addHandlerRender(controlNav);
  answerView.addHandlerRender(controlInterpretation);
};

init();
